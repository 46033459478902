<template>
  <main>
    <sqr-hero
      title="sign_out_title"
      subtitle="sign_out_subtitle"
      color="light"
      is-bold
      :is-loading="loading"
    />

    <section class="section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-12-mobile is-10-tablet is-6-desktop">
            <div class="content">
              <p class="notification is-success is-size-4" v-show="!authenticated">
                {{$t('sign_out_success')}}</p>
              <p>
                <sqr-router-link
                  icon="sign-in"
                  label="sign_out_sign_in"
                  color="primary"
                  is-outlined
                  size="large"
                  is-fullwidth
                  to-name="sign-in"
                />
              </p>
            </div>
          </div>
        </div>

      </div>
    </section>

  </main>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex';
  import SqrRouterLink from '@/sqrd/SqrRouterLink';

  export default {
    name: 'SignOut',
    components: {SqrRouterLink},
    computed: {
      ...mapGetters('auth', ['authenticated', 'loading'])
    },
    mounted () {
      this.signOut({});
    },
    methods: {
      ...mapActions('auth', ['signOut'])
    }
  };
</script>
